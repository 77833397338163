import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import PageSection from '../components/PageSection'
import SectionHeaderRow from '../components/SectionHeaderRow'
// import { getArtists } from '../hooks/getArtists'
// import { getArtworks } from '../hooks/getArtworks'
import {
  kbImagifyDyn,
  artistDisplayName,
  artistSlugName,
} from '../components/GalleryUtils'
import appConfig from '../appConfig'

function GalleryOverview({ location }) {
  // const artists = getArtists()
  // const artworks = getArtworks()

  return (
    <Layout location={location} showSlider={false}>
      <SEO keywords={[`kuboshow`, `galerie`, `2024`]} title="Künstler 2024" />
      {appConfig.previewGalleryAnnouncement === true ? (
        <PageSection>
          <SectionHeaderRow
            headline="KÜNSTLER 2024"
            entryText="KUBOSHOW KUNSTMESSE – Flottmann-Hallen Herne - 21.- 22. Oktober 23"
          />
        </PageSection>
      ) : (
        <PageSection>
          <SectionHeaderRow
            headline="KÜNSTLER 2024"
            entryText="Hier findest Du die Künstler 2024."
          />
          <div className="row mt-3">
            {/* {artists.map((artist) => {
              // console.log(artist)
              const artistsArtworks = artworks.filter(
                (artwork) => artwork.artistId === artist.id
              )
              // console.log(artistsArtworks)
              let foundArtwork =
                artistsArtworks.find(
                  (artwork) => artwork.artworkIndex === artist.previewImage
                ) || artistsArtworks[0]
              // console.log(foundArtwork)

              if (!foundArtwork || !foundArtwork.title) {
                console.log(
                  'No artworks found for artist: ' + artistDisplayName(artist)
                )
                return null
              }

              const foundImage = foundArtwork?.files[0] //|| artistsArtworks[0].files[0]

              return (
                <div key={artist.id} className="col-4 col-sm-3 col-md-2">
                  <Link to={`/gallery/${artistSlugName(artist)}`}>
                    <figure className="d-flex flex-column align-items-center">
                      <img
                        className="w-100 h-auto"
                        alt={foundArtwork.title}
                        src={kbImagifyDyn(foundImage)}
                      />
                      <figcaption className="mt-2 text-uppercase text-center text-dark">
                        {artistDisplayName(artist)}
                      </figcaption>
                    </figure>
                  </Link>
                </div>
              )
            })} */}
          </div>
        </PageSection>
      )}
    </Layout>
  )
}

export default GalleryOverview
